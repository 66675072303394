import { zodResolver } from "@hookform/resolvers/zod";
import { Image } from "@mantine/core";
import { useForm } from 'react-hook-form';
import { z } from "zod";
import image from "../../assets/svg/logo-signin.svg";
import { InputForm, TextInfo } from '../../components';
import { ButtonComponent } from "../../components/Button";
import { useAuth } from "../../hooks/auth";
import { Container, Form } from "./styles";

const schema = z.object({
  email: z.string()
    .nonempty("Email é obrigatório")
    .email("Email inválido")
    .toLowerCase(),
  password: z.string()
    .nonempty("Senha é obrigatório")
    .toLowerCase(),
});

type UserFormData = z.infer<typeof schema>
export function SignIn(){
  const { signIn, loading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: zodResolver(schema),
    defaultValues: { }
  });

  const handleSignIn = async (data: UserFormData) : Promise<void> => {
    await signIn(data);
  }

  return (
    <Container>
      <Image src={image} />
      <Form>
        <div>
          <InputForm register={register("email")} label="Email" placeholder="exemplo@exemplo.com.br" />
          {errors.email && <TextInfo message="Este campo é de preenchimento obrigatório." />}
        </div>

        <div>
          <InputForm register={register("password")} label="Senha" placeholder="*******" type= "password" />
          {errors.password && <TextInfo message="Este campo é de preenchimento obrigatório." />}
        </div>
        <ButtonComponent isLoading={loading} title="Entrar" onClick={handleSubmit(handleSignIn)}/>
      </Form>
    </Container>
  );
}