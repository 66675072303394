
import { ButtonHTMLAttributes } from 'react';
import { Spinner } from 'reactstrap';
import { Button } from './styles';

type Props =  ButtonHTMLAttributes<HTMLButtonElement> &{
  title: string
  isLoading?: boolean
}
export function ButtonComponent({ title, isLoading = false, disabled, ...rest }: Props){
  return (
    <Button disabled={disabled || isLoading}{...rest}>
      {isLoading ? <Spinner color='light' /> : <label htmlFor={title} style={{ cursor: "pointer" }}>{title}</label>}
    </Button>
  );
}