import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { UNIT_LESSON } from "../keys";
export type UnitLesson = {
  id?: string
  unit_id?: string
  teacher?: Teacher
  date: string
  year?: number
  quarter?: number
  number?: number
  students: Student[]
  questions: Question[]
  dateFormatted?: string
  initial_date?: string
  final_date?: string
}

export type Teacher = {
  id: string
  name: string
}

export type Student = {
  id: string
  name: string
  present: boolean
}

export type Question = {
  key: string
  label: string
  value: any
}

interface UnitLessonContextData {
  unitLesson: UnitLesson
  setUnitLesson: React.Dispatch<React.SetStateAction<UnitLesson>>
}

interface AuthProviderProps {
  children: ReactNode;
}

const UnitLessonContext = createContext<UnitLessonContextData>({} as UnitLessonContextData);

function UnitLessonProvider({ children }: AuthProviderProps) {
  const [unitLesson, setUnitLesson] = useState<UnitLesson>({ teacher: {} as Teacher, date: "", questions: new Array<Question>(), students: new Array<Student>() });

  const handleChange = useCallback(() => {
    const unitLessonStorage = localStorage.getItem(UNIT_LESSON);
    
    if(unitLessonStorage) {
      const compare = (a: Student, b: Student): number =>  {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      const unit = JSON.parse(unitLessonStorage) as UnitLesson;

      if(!unit?.id) return;

      const unitLessonFormatted: UnitLesson = {
        ...unit,
        students: unit.students.sort(compare)
      }
      
      setUnitLesson(unitLessonFormatted)
    };

    if(unitLesson?.id) localStorage.setItem(UNIT_LESSON, JSON.stringify(unitLesson));
  }, [unitLesson])

  useEffect(() => { handleChange() }, []);
  useEffect(() => { 
    if(unitLesson?.id)
      localStorage.setItem(UNIT_LESSON, JSON.stringify(unitLesson)) 
  }, [unitLesson]);

  return (
    <UnitLessonContext.Provider value={{ unitLesson, setUnitLesson }}>
      {children}
    </UnitLessonContext.Provider>
  );
}

function useUnitLesson(): UnitLessonContextData {
  const context = useContext(UnitLessonContext);
  return context;
}

export { UnitLessonProvider, useUnitLesson };
