import axios, { AxiosHeaders, AxiosInstance, AxiosResponse } from "axios";
import { environment } from '../../environments';
import { ACCESS_TOKEN } from '../../keys';

export class AxiosHttpClient {
  protected static api = (url: string = ""): AxiosInstance => {
    let axiosInstance = axios.create({
      baseURL: environment.apiURL,
      timeout: 10000,
      timeoutErrorMessage: "Network Error",
      validateStatus: (status) => {
        return status >= 200 && status <= 599;
      },
    });

    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await localStorage.getItem(ACCESS_TOKEN);
        if (config?.headers){
          if(token) (config?.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
          (config?.headers as AxiosHeaders).set("Content-Type", "application/json");
        }

        return config;
      },
      (error) => Promise.reject(error),
    );

    function formataErro(config: AxiosResponse) {
      if (config.status >= 400) {
        let response = [];
        const { data, status } = config;

        if (status === 500) response.push('Ocorreu um erro relacionado ao banco de dados');
        else response.push(data?.message);
        if(response.length === 0) response.push("Não foi possível processar a solicitação.");
        
        return Promise.reject(response);
      } else {
        return config;
      }
    }

    axiosInstance.interceptors.response.use(
      (config) => {
        return formataErro(config);
      },
      function (error) {
        let response: any = [];
        if (error?.message === 'Network Error') {
          response.push('Ocorreu um problema com a conexão\nO serviço pode estar fora do ar.');
        }
        return Promise.reject(response);
      }
    );

    return axiosInstance;
  };

  protected static get axiosHttpClient(): AxiosInstance { return this.api(); }
}