import { Select, createStyles, rem } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { Unit } from "../../entities/unit";
import { BiService } from "../../services/bi/bi.service";
import { Lesson, LessonService } from "../../services/lesson/lesson.service";
import { UnitService } from "../../services/unit/unit.service";
import { Card, Chart } from "./styles";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    marginBottom: rem(2.4),
  },

  input: {
    height: rem(56),
    paddingTop: rem(18),
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

export function Classes() {
  const { classes } = useStyles();
  const [unitites, setUnities] = useState<Unit[]>(new Array<Unit>());
  const [lessons, setLessons] = useState<Lesson[]>(new Array<Lesson>());
  const [unitiesSelected, setUnitiesSelected] = useState<string[]>([]);
  const [lessonsSelected, setLessonsSelected] = useState<string | null>(null);
  const [data, setData] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const option = {
    legend: {
      top: "bottom",
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: "Salvar imagem",
        },
      },
    },
    // toolbox: {
    //   show: true,
    //   feature: {
    //     mark: { show: true },
    //     dataView: { show: true, readOnly: false },
    //     restore: { show: true },
    //     saveAsImage: { show: true }
    //   }
    // },
    series: [
      {
        // name: 'Nightingale Chart',
        type: "pie",
        radius: [50, 250],
        center: ["50%", "50%"],
        roseType: "area",
        itemStyle: {
          borderRadius: 8,
        },
        data,
      },
    ],
  };

  const fetch = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await UnitService.get();
      const responseLesson = await LessonService.getAtDate();
      setUnities(response);
      setLessons(responseLesson);
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const unit_ids: string[] = [];
      unitiesSelected.forEach((uni) => {
        const unitId = unitites.find((u) => u.name === uni)?.id;
        if (unitId) unit_ids.push(unitId);
      });

      if (lessonsSelected) {
        const [anoS, trimestreS, licaoS] = lessonsSelected?.split(";");
        const year = Number(anoS.replace(/[^0-9]/g, ""));
        const quarter = Number(trimestreS.replace(/[^0-9]/g, ""));
        const number = Number(licaoS.replace(/[^0-9]/g, ""));

        const response = await BiService.getPresentMissingRelation({
          number,
          quarter,
          year,
          unit_ids,
        });

        if (!response?.items?.length) {
          toast.warning("Não há dados para serem exibidos", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setData([]);
          return;
        }

        let unidades: any = [];
        response?.items?.forEach((r) => {
          const value = r?.result?.find(
            (k) => k?.key === "Percentual de presença",
          )?.value;
          unidades.push({
            value,
            name: `${r.label} - ${value?.toFixed(2)}%`,
          });
        });

        setData(unidades?.sort((a: any, b: any) => b.value - a.value));
      }
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Card
      style={{
        gap: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{ display: "flex", gap: 8, alignItems: "end", width: "100%" }}
      >
        <Select
          mt="md"
          withinPortal
          data={lessons.map(
            (l) =>
              `Ano: ${l.year}; Trimestre: ${l.quarter}; Lição: ${l.number}`,
          )}
          placeholder="Selecione uma lição"
          label="Lição"
          classNames={classes}
          style={{ flex: 1 }}
          onChange={setLessonsSelected}
        />
        <Button onClick={() => fetchData()} disabled={isLoading}>
          {isLoading ? (
            <Spinner color="light" />
          ) : (
            <label htmlFor="Buscar" style={{ cursor: "pointer" }}>
              Buscar
            </label>
          )}
        </Button>
      </div>
      <Chart option={option} />
    </Card>
  );
}

export const Button = styled.button`
  padding: 0rem 1.7rem;
  margin-bottom: 0.2rem;
  border-radius: 0.4rem;

  background-color: #3439a5;
  border: 0.1rem solid #2b2fa3;

  height: 3.5rem;
  min-width: 8.8rem;

  label {
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
  }
`;
