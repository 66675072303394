import { Divider } from "@mantine/core";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../hooks/auth";

export function Manager(){
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const charts = [
    {
      title: "Relação de presenças e faltas",
      description: "Representação gráfica de presença de membros por unidade em uma lição.",
      to: "/membrosFaltosos"
    },
    {
      title: "Totalizador de perguntas",
      description: "Representação gráfica da resposta de uma determinada pergunta por trimestre.",
      to: "/relacaoPerguntas"
    },
    {
      title: "Percentual de membros presentes",
      description: "Representação gráfica do percentual de membros presentes por classe em uma lição.",
      to: "/percentMembers"
    },
  ]
  return (
    <>
      <Header>
        <div style={{ cursor: "pointer", display: "flex" }} onClick={() => signOut()}>
          <FiLogOut/>
        </div>
      </Header>
      <Container>
        {
          charts.map((c, index) => (
            <Card key={index} onClick={() => navigate(c.to)}>
              <div style={{ display: "flex", flexDirection: 'column', gap: 4, cursor: "pointer"}}>
                <label htmlFor={c.title} style={{fontSize: 16, cursor: "pointer"}}>{c.title}</label>
                <Divider/>
              </div>
              <div style={{cursor: "pointer"}}>
                <label htmlFor={c.description} style={{fontSize: 14, cursor: "pointer"}}>{c.description}</label>
              </div>
            </Card>
          ))
        }
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  padding: .8rem;

  gap: 0.8rem;
  flex-wrap: wrap;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 1.8rem 1.6rem;

  color: #3439A5;
  font-size: 2.4rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: .8rem;
  padding: .8rem;
  border: 1px solid #C0C0C9;
  border-radius: .4rem;

  cursor: pointer;
  width: 36rem;
`;