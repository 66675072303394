import styled from "styled-components";

export const Input = styled.input`
  background-color: #CACAE4;
  border: .1rem solid #A8AABC;

  :focus {
    border: .1rem solid #A8AABC;
  }

  ::placeholder {
    color: #FFF;
    opacity: 1;
  }

  text-decoration: none;
  color: #FFF;
  font-size: 1.6rem;

  padding: 1.6rem;
  border-radius: .8rem;
`;

export const Label = styled.label`
  font-size: 1.6rem;
`