import { createBrowserRouter, RouteObject, RouterProvider } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { AttendanceRecord } from "../pages/AttendanceRecord";
import { InformationRecord } from "../pages/InformationRecord";
import Lessons from "../pages/Lessons";
import { Manager } from "../pages/Manager";
import { Classes } from "../pages/Manager/classes";
import { MembrosFaltosos } from "../pages/Manager/membrosFaltosos";
import { RelacaoPerguntas } from "../pages/Manager/relacaoPerguntas";
import { Register } from "../pages/Register";
import { SignIn } from "../pages/SignIn";

export function Routes(){
  const { user } = useAuth();

  const routesAuthenticated: RouteObject[] = [
    {
      path: "/",
      element: <Lessons />
    },
    {
      path: "/attendanceRecord",
      element: <AttendanceRecord />
    },
    {
      path: "/informationRecord",
      element: <InformationRecord />
    },
    {
      path: "/register",
      element: <Register />
    },
  ]

  const routes: RouteObject[] = [
    {
      path: "/",
      element: <SignIn />
    }
  ];

  const routesMenager: RouteObject[] = [
    {
      path: "/",
      element: <Manager />
    },
    {
      path: "/membrosFaltosos",
      element: <MembrosFaltosos />
    },
    {
      path: "/relacaoPerguntas",
      element: <RelacaoPerguntas />
    },
    {
      path: "/percentMembers",
      element: <Classes />
    },
  ];

  let routesValidated = user?.id 
    ? user?.roles.find((role) => role === 'manager_role') 
      ? routesMenager 
      : routesAuthenticated
    : routes

  routesValidated = routesValidated.map(r => {
    return {
      ...r,
      // errorElement: <NotFound />
    }
  });

  const router = createBrowserRouter(routesValidated);

  return(
    <RouterProvider router={router} />
  )
}