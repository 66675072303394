import styled from "styled-components";

export const Button = styled.button`
  padding: 1.6rem 0rem;
  border-radius: 0.8rem;

  background-color: #3439a5;
  border: 0.1rem solid #2b2fa3;
  width: 100%;

  label {
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
  }
`;
