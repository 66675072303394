const env = process.env.NODE_ENV || "development";

export type Environment = {
  apiURL: string;
}

export const environment: Environment =
  env === "production"
    ? require("./environments.prod").default
    : require("./environments").default;
