import { Unit } from "../../entities/unit";
import { AxiosHttpClient } from "../httpClient/axios-http-client.service";

export class UnitService extends AxiosHttpClient {
  public static async get(): Promise<Unit[]> {
    const { data: { data } } = await this.axiosHttpClient.get("/units");
    return data;
  }

  public static async getMissingStudents(): Promise<{ name: string }[]> {
    const { data: { data } } = await this.axiosHttpClient.get("/units/missing-students");
    return data;
  }
}