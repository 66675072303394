import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  // max-width: 480px !important;
  min-width: 280px !important;
  background-color: #f0f0f5;
  height: 100dvh;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #3439a5;
  color: #fff;
  height: 18rem;
  margin-bottom: 2.4rem;
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: -3.2rem;

  gap: 1.6rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  gap: 0.4rem;

  padding: 2.4rem 1.6rem;
`;

export const Input = styled.input`
  background-color: #cacae4;
  border: 0.1rem solid #a8aabc;

  :focus {
    border: 0.1rem solid #a8aabc;
  }

  ::placeholder {
    color: #fff;
    opacity: 1;
  }

  text-decoration: none;
  color: #fff;
  font-size: 1.6rem;

  padding: 1.6rem;
  border-radius: 0.8rem;
`;
