import { FiChevronLeft, FiUserPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "../../components/Button";
import { useUnitLesson } from "../../hooks/unitLesson";
import {
  ContainerButton,
  Content,
  MissingStudentsBox,
} from "../../styles/components";
import { Empty, FormHeader, Header, ItemMarker, Legend } from "./styles";

export function AttendanceRecord() {
  const { unitLesson, setUnitLesson } = useUnitLesson();
  const navigate = useNavigate();

  const handleSelect = (name: string): void => {
    const students = unitLesson?.students.map((i) => {
      return { ...i, present: i.name == name ? !i.present : i.present };
    });

    const unit = { ...unitLesson, students };
    setUnitLesson(unit);
  };

  const handleSubmit = () => {
    navigate("/informationRecord");
  };

  return (
    <>
      <Header>
        <div>
          <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            <div
              style={{
                gap: 16,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FiChevronLeft />
              <label htmlFor="">Registro de presença</label>
            </div>

            <div style={{ display: "flex", gap: 8 }}>
              <span style={{ fontSize: 16 }}>
                Trimestre: {unitLesson.quarter}
              </span>
              <span style={{ fontSize: 16 }}>Lição: {unitLesson.number}</span>
            </div>
          </div>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/register")}
        >
          <FiUserPlus />
        </div>
      </Header>

      <Content>
        <FormHeader>
          <h2 style={{ fontSize: 18 }}>Alunos</h2>
          <div style={{ display: "flex", gap: 8 }}>
            <Legend primary={true}> Presente </Legend>
            <Legend> Faltou </Legend>
          </div>
        </FormHeader>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontSize: 14 }}>Selecione os alunos presentes </span>
          {unitLesson?.students.filter((i) => i.present).length > 0 && (
            <span>
              Membro(s) presente(s):{" "}
              <span>
                {unitLesson?.students.filter((i) => i.present).length}
              </span>{" "}
            </span>
          )}
        </div>

        {/* <Content> */}
        {unitLesson?.students.map((i, index) => (
          <ItemMarker
            onClick={() => handleSelect(i?.name)}
            key={i.id}
            marked={i.present}
          >
            <label htmlFor={i.name}>{i.name.toLocaleUpperCase()}</label>
          </ItemMarker>
        ))}
        {/* </Content> */}

        {unitLesson?.students.length === 0 && (
          <Empty>
            <label htmlFor="">Não possui itens</label>
          </Empty>
        )}
      </Content>

      <ContainerButton>
        <ButtonComponent
          disabled={unitLesson?.students.filter((i) => i.present).length === 0}
          type="button"
          title="Continuar"
          onClick={handleSubmit}
        />
      </ContainerButton>
    </>
  );
}
