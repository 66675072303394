import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ACCESS_TOKEN, REFRESH_TOKEN, UNIT_LESSON, USER_INFO } from "../keys";
import { AuthService } from "../services/auth/auth.service";

export type TAuthParams = {
    email: string
    password: string
}

export type Auth = {
    code: number
    message: string
    data: {
        token: string
        user: User
    }
}

type TRoles = "teacher_role" | "manager_role"

export type User = {
    id: string
    name: string
    email: string
    phone: string
    type: string
    created_at: string
    updated_at: string
    church_id: string
    roles: TRoles[]
}

interface AuthContextData {
    user: User
    setUser: React.Dispatch<React.SetStateAction<User>>

    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>

    signIn: (params: TAuthParams) => Promise<void>
    signOut: () => void
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
    const [user, setUser] = useState<User>({} as User);
    const [auth, setAuth] = useState<Auth>({} as Auth);
    const [loading, setLoading] = useState<boolean>(false);

    async function signIn(params: TAuthParams = {} as TAuthParams): Promise<void> {
        setLoading(true);
        try {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem(USER_INFO);
            localStorage.removeItem(UNIT_LESSON);
            
            const data = await AuthService.signIn({...params, password: params.password});
            
            localStorage.setItem(ACCESS_TOKEN, data?.data?.token);
            localStorage.setItem(USER_INFO, JSON.stringify(data?.data?.user));
            setAuth(data);
            setUser(data?.data?.user);
        } catch (error: any) {
            toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
        } finally {
            setLoading(false);
        }
    }

    async function signOut(): Promise<void> {
        setLoading(true);
        try {
            localStorage.clear();
            setAuth({} as Auth);
            setUser({} as User);
        } catch (error: any) {
            toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const userInfo = localStorage.getItem(USER_INFO);
        if(userInfo) setUser(JSON.parse(userInfo));
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser, loading,  setLoading, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);
    return context;
}

export { AuthProvider, useAuth };
