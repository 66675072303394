import styled from "styled-components";

export const Header = styled.div`
  height: 10vh;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding: 2.4rem 1.6rem;
  background-color: #3439A5;
  color: #FFF;
  font-size: 2.4rem;
  font-weight: 500;
`;

export const Form = styled.form`
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: .4rem;

  padding: 2.4rem 1.6rem;
`;

export const Button = styled.button`
  padding: 1.6rem 0rem;
  border-radius: .8rem;

  background-color: #3439A5;
  border: .1rem solid #2B2FA3;

  label {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: bold;
  }
`;