import { InputHTMLAttributes } from "react";
import { Input, Label } from "./styles";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  title: string
  register: any
}
export function InputComponent({ title, register, ...rest }: any){
  return(
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Label htmlFor={title}>{title}</Label>
      <Input {...rest} {...register} />
    </div>
  )
}