import { MantineProvider, rem } from "@mantine/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./hooks/auth";
import { UnitLessonProvider } from "./hooks/unitLesson";
import { Routes } from "./routes";
import { Root } from "./styles/components";

console.log("AppVersion: v1.0");

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <AuthProvider>
        <UnitLessonProvider>
          <Root>
            <Routes />
          </Root>
        </UnitLessonProvider>
      </AuthProvider>
      <ToastContainer
        limit={1}
        toastStyle={{
          fontSize: rem(24),
        }}
      />
    </MantineProvider>
  );
}

export default App;
