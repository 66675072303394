import moment from "moment";
import { memo, useCallback, useEffect, useState } from "react";
import { FiCheckCircle, FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../components/Button";
import { useAuth } from "../../hooks/auth";
import { Student, UnitLesson, useUnitLesson } from "../../hooks/unitLesson";
import { UnitService } from "../../services/unit/unit.service";
import {
  UnitLessonService,
  UnitiesLessonsYear,
} from "../../services/unitLesson/unitLesson.service";
import {
  ContainerButton,
  Content,
  MissingStudentsBox,
} from "../../styles/components";
import { Empty, Header, ItemMarker } from "./styles";

function Lessons() {
  const [lessons, setLessons] = useState<UnitiesLessonsYear[]>(
    new Array<UnitiesLessonsYear>(),
  );
  const [missingStudents, setMissingStudents] = useState<{ name: string }[]>(
    [],
  );
  const [lessonId, setLessonId] = useState<string | null | undefined>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { setUnitLesson } = useUnitLesson();
  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const handleSelect = useCallback(
    (unitLessonSelected: UnitLesson): void => {
      const unit =
        lessonId == unitLessonSelected?.id ? null : unitLessonSelected.id;
      setLessonId(unit);
    },
    [lessonId],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      if (lessonId) {
        const response = await UnitLessonService.getUnitLessonById(lessonId);
        setUnitLesson(response);
        navigate("/attendanceRecord");
      }
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setLoading(false);
    }
  }, [lessonId]);

  const getUnitLesson = useCallback(async (): Promise<void> => {
    try {
      const year = new Date().getFullYear();
      const response = await UnitLessonService.getUnitiesLessonsByYear(year);

      const compare = (a: Student, b: Student): number => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      };

      const dadosOrdenados = response.map((unit) => {
        return {
          ...unit,
          unit_lessons: unit.unit_lessons.map((i) => {
            const startDate = moment(i.initial_date);
            const endDate = moment(i.final_date);
            return {
              ...i,
              dateFormatted: `(${startDate.format("DD/MM/yyyy")} à ${endDate.format("DD/MM/yyyy")})`,
              students: i?.students
                ? i?.students?.sort(compare)
                : new Array<Student>(),
            };
          }),
        };
      });

      setLessons(dadosOrdenados);
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    }
  }, []);

  const getMissingStudents = async () => {
    try {
      const response = await UnitService.getMissingStudents();
      setMissingStudents(response);
    } catch (err) {
      console.error("error getting missing students list: ", err);
    }
  };

  useEffect(() => {
    getUnitLesson();
    getMissingStudents();
  }, [getUnitLesson]);

  return (
    <>
      <Header>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <label htmlFor="">Lições</label>
          <div
            style={{ cursor: "pointer", display: "flex" }}
            onClick={() => signOut()}
          >
            <FiLogOut />
          </div>
        </div>
      </Header>

      <Content>
        {missingStudents.length > 0 && (
          <MissingStudentsBox>
            <strong>Atenção professor!</strong>
            <p>
              Os alunos abaixo faltaram consecutivamente nas últimos 3 ou mais
              sábados.
            </p>
            <ul>
              {missingStudents.map((student) => (
                <li key={student.name}>{student.name}</li>
              ))}
            </ul>
          </MissingStudentsBox>
        )}

        {lessons.map((lesson, index) => {
          return (
            <div key={index}>
              <div>
                <label
                  htmlFor=""
                  style={{
                    fontSize: 24,
                    color: "#494646",
                    fontFamily: "Heebo",
                    fontWeight: 500,
                  }}
                >
                  {lesson.year}
                </label>
              </div>
              <div>
                <label
                  htmlFor=""
                  style={{
                    fontSize: 18,
                    color: "#494646",
                    fontFamily: "Heebo",
                    fontWeight: 500,
                  }}
                >
                  Trimestre {lesson.quarter}
                </label>
              </div>
              <List
                itens={lesson?.unit_lessons}
                handleSelect={handleSelect}
                idUnitLesson={lessonId}
              />
            </div>
          );
        })}

        {lessons?.length === 0 && (
          <Empty>
            <label htmlFor="">Não possui lições</label>
          </Empty>
        )}
      </Content>

      <ContainerButton>
        <ButtonComponent
          disabled={!lessonId || loading}
          type="button"
          title="Continuar"
          onClick={handleSubmit}
          isLoading={loading}
        />
      </ContainerButton>
    </>
  );
}
export default memo(Lessons);

type Props = {
  idUnitLesson: string | null | undefined;
  itens: UnitLesson[];
  handleSelect: (i: UnitLesson) => void;
};
const List = memo(({ itens, handleSelect, idUnitLesson }: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {itens.map((i, index) => (
        <ItemMarker
          onClick={() => !i?.teacher?.id && handleSelect(i)}
          disabled={!!i?.teacher?.id}
          key={i.id}
          marked={i?.id === idUnitLesson}
        >
          <label htmlFor={i.number?.toString()}>
            Lição: {i.number} {i.dateFormatted}
          </label>
          {!!i?.teacher?.id && <FiCheckCircle />}
        </ItemMarker>
      ))}
    </div>
  );
});
