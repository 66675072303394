import styled from "styled-components";

export const Root = styled.div`
  position: relative;
  height: 100dvh !important;
  width: 100vw;
  max-width: 480px !important;
  min-width: 280px !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: #f0f0f5;
`;

export const Container = styled.div``;

export const ContainerButton = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.2rem 1.2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  gap: 0.4rem;
  padding: 2.4rem 1.6rem;
  padding-bottom: 10rem;
  background-color: #f0f0f5;
`;

export const MissingStudentsBox = styled.section`
  font-size: 12px;

  background-color: #f7e5b2;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #f7c94a;

  color: #7d5c01;
  strong {
    font-size: 14px;
  }
`;
