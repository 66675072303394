import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  // max-width: 480px !important;
  // min-width: 280px !important;
  background-color: #f0f0f5;
  height: 100dvh;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  gap: 1.6rem;

  padding: 2.4rem 1.6rem;
  background-color: #3439a5;

  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
`;

export const Form = styled.form`
  height: 80vh;
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  overflow-y: auto;
  gap: 0.4rem;

  padding-bottom: 6rem;
`;

export const Button = styled.button`
  padding: 1.6rem 0rem;
  border-radius: 0.8rem;

  background-color: #3439a5;
  border: 0.1rem solid #2b2fa3;

  label {
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
  }
`;

export const ContainerModal = styled.div`
  flex: 1;
  flex-direction: column;

  height: 100dvh;
  display: flex;

  align-items: center;
  justify-content: center;

  background-color: #f0f0f5;

  gap: 2.4rem;
`;

export const Text = styled.span`
  font-size: 1.6rem;
  text-align: center;

  font-family: "Heebo", sans-serif;

  background-color: #f0f0f5;
`;
