import moment from "moment";
import { AxiosHttpClient } from "../httpClient/axios-http-client.service";

export type Lesson = {
  year: number
  quarter: number
  number: number
  start: string
  end: string
  register: string
  id: string
}

export class LessonService extends AxiosHttpClient {
  public static async getAtDate(date: string = moment().format('YYYY-MM-DD')): Promise<Lesson[]> {
    const { data: { data } } = await this.axiosHttpClient.get(`/quarters/lessons/until-date/${date}`);
    return data;
  }
}