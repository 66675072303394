import { UnitLesson } from "../../hooks/unitLesson";
import { AxiosHttpClient } from "../httpClient/axios-http-client.service";

export type UnitiesLessonsYear = {
  year: number
  quarter: number
  unit_lessons: UnitLesson[]
}

export class UnitLessonService extends AxiosHttpClient {
  public static async get(): Promise<UnitLesson[]> {
    const { data: { data } } = await this.axiosHttpClient.get("/unit-lessons/quarter");
    return data;
  }

  public static async getUnities(): Promise<UnitLesson[]> {
    const { data: { data } } = await this.axiosHttpClient.get("/units");
    return data;
  }

  public static async getUnitiesLessonsByYear(year: number): Promise<UnitiesLessonsYear[]> {
    const { data: { data } } = await this.axiosHttpClient.get(`/unit-lessons/year`, { params: { year } });
    return data;
  }

  public static async getUnitLessonById(id: string): Promise<UnitLesson> {
    const { data: { data } } = await this.axiosHttpClient.get(`/unit-lessons/${id}`);
    return data;
  }

  public static async patch(unitLessonId: string | undefined, unitLesson: UnitLesson): Promise<void> {
   await this.axiosHttpClient.patch(`/unit-lessons/${unitLessonId}`, unitLesson);
  }
}