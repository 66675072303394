import { useEffect, useState } from "react";
import { FiCheckCircle, FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TextInfo } from "../../components";
import { ButtonComponent } from "../../components/Button";
import { InputComponent } from "../../components/Input";
import { useAuth } from "../../hooks/auth";
import { UnitLesson, useUnitLesson } from "../../hooks/unitLesson";
import { UNIT_LESSON } from "../../keys";
import { UnitLessonService } from "../../services/unitLesson/unitLesson.service";
import { ContainerButton, Content } from "../../styles/components";
import { ContainerModal, Form, Header, Text } from "./styles";

export function InformationRecord() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { unitLesson, setUnitLesson } = useUnitLesson();
  const { user } = useAuth();
  const [totalStudentsPresent, setTotalStudantsPresent] = useState<number>(
    unitLesson?.students?.length,
  );
  const navigate = useNavigate();

  const [isFinished, setIsFinished] = useState<boolean>(false);

  const isEmpty = (value: any): boolean => {
    return ["", null, undefined].includes(value);
  };

  const handleSubmitForm = async (): Promise<void> => {
    let err: string[] = [];
    unitLesson.questions.forEach((question) => {
      if (question.value < 0) err.push(question.key);
      else if (question.value > totalStudentsPresent) err.push(question.key);
      else if (isEmpty(question.value)) err.push(question.key);
    });
    setErrors(err);
    if (err.length > 0) return;

    setIsLoading(true);

    try {
      await UnitLessonService.patch(unitLesson.id, unitLesson);
      toast.success("Registro salvo com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setUnitLesson({
        ...unitLesson,
        teacher: {
          id: user.id,
          name: user.name,
        },
      });

      setIsFinished(true);

      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeValue = (key: string, value: string): void => {
    var apenasNumeros = value.replace(/\D/g, "");

    const questions = unitLesson.questions.map((i) => {
      if (i.key === key) {
        return apenasNumeros == ""
          ? { ...i, value: null }
          : { ...i, value: Number(apenasNumeros) };
      }

      return i;
    });

    const unit = { ...unitLesson, questions };
    setUnitLesson(unit);

    if (
      isEmpty(value) ||
      Number(value) < 0 ||
      Number(value) > totalStudentsPresent
    )
      setErrors([...errors, key]);
    else setErrors(errors.filter((i) => i != key));
  };

  const validarNumero = (event: any) => {
    var tecla = event.which || event.keyCode;

    if (tecla == 8 || tecla == 9 || tecla == 13 || tecla == 46) {
      return true;
    }
    if (tecla < 48 || tecla > 57) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const storageUnitLesson = localStorage.getItem(UNIT_LESSON);
    if (storageUnitLesson) {
      const unit = JSON.parse(storageUnitLesson) as UnitLesson;

      setUnitLesson(unit);
      setTotalStudantsPresent(unit.students.length);
    }
  }, []);

  return (
    <>
      {!isFinished ? (
        <>
          <Header>
            <div
              style={{ cursor: "pointer", display: "flex" }}
              onClick={() => navigate(-1)}
            >
              <FiChevronLeft />
            </div>
            <label htmlFor="">Registro de informações</label>
          </Header>

          <Content>
            <Form>
              {unitLesson?.questions.map((question) => (
                <div key={question.key}>
                  <InputComponent
                    title={question.label}
                    value={question?.value}
                    type="text"
                    maxLength={3}
                    onKeyPress={validarNumero}
                    onChange={(event: any) =>
                      handleChangeValue(question.key, event?.target?.value)
                    }
                  />
                  {question.value > totalStudentsPresent && (
                    <TextInfo
                      message={`${question.label} não pode ser maior que: ${totalStudentsPresent}.`}
                    />
                  )}
                  {question.value < 0 && (
                    <TextInfo
                      message={`${question.label} não pode ser menor que: 0.`}
                    />
                  )}
                  {!question.value && errors.includes(question.key) && (
                    <TextInfo message="Este campo é de preenchimento obrigatório." />
                  )}
                </div>
              ))}
            </Form>
          </Content>

          <ContainerButton>
            <ButtonComponent
              disabled={errors.length > 0}
              isLoading={isLoading}
              type="button"
              title="Finalizar"
              onClick={handleSubmitForm}
            />
          </ContainerButton>
        </>
      ) : (
        <ModalSuccess />
      )}
    </>
  );
}

export const ModalSuccess = () => {
  return (
    <ContainerModal>
      <FiCheckCircle size={56} color="#16A34A" />
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Text style={{ fontWeight: 500, color: "#292524" }}>
          {" "}
          Apontamentos realizados com sucesso!{" "}
        </Text>
        <Text style={{ color: "#57534E" }}>
          {" "}
          Em instantes você será redirecionado para a <br /> lista de lições,
          tenha um bom sábado!{" "}
        </Text>
      </div>
    </ContainerModal>
  );
};
