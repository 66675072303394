import { MultiSelect, Select, createStyles, rem } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { Unit } from "../../entities/unit";
import { BiService } from "../../services/bi/bi.service";
import { Lesson, LessonService } from "../../services/lesson/lesson.service";
import { UnitService } from "../../services/unit/unit.service";
import { Card, Chart } from "./styles";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    marginBottom: rem(2.4),
  },

  input: {
    height: rem(56),
    paddingTop: rem(18),
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

export function MembrosFaltosos() {
  const { classes } = useStyles();
  const [unitites, setUnities] = useState<Unit[]>(new Array<Unit>());
  const [lessons, setLessons] = useState<Lesson[]>(new Array<Lesson>());
  const [unitiesSelected, setUnitiesSelected] = useState<string[]>([]);
  const [lessonsSelected, setLessonsSelected] = useState<string | null>(null);
  const [unidades, setUnidades] = useState<string[]>([]);
  const [presentes, setPresentes] = useState<number[]>([]);
  const [faltantes, setFaltantes] = useState<number[]>([]);
  const [total, setTotal] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const option = {
    title: {
      text: "Relação de presenças e faltas",
      left: "center",
    },
    legend: {
      bottom: "bottom",
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: "Salvar imagem",
        },
      },
    },
    tooltip: {
      fontSize: 36,
      formatter: (data: any) => {
        return `<b>${data?.name}</b> </br> Quantidade: <b>${data?.value}</b>`;
      },
    },
    xAxis: [
      {
        type: "category",
        data: unidades,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        data: presentes,
        name: "Presentes",
        type: "bar",
        showBackground: true,
        stack: "total",
        emphasis: {
          focus: "series",
        },
        color: "blue",
        label: {
          show: true,
          fontSize: 32,
        },
      },
      {
        data: faltantes,
        name: "Faltantes",
        type: "bar",
        stack: "total",
        emphasis: {
          focus: "series",
        },
        showBackground: true,
        color: "orange",
        label: {
          show: true,
          fontSize: 40,
        },
      },
    ],
  };

  const fetch = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await UnitService.get();
      const responseLesson = await LessonService.getAtDate();
      setUnities(response);
      setLessons(responseLesson);
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const unit_ids: string[] = [];
      unitiesSelected.forEach((uni) => {
        const unitId = unitites.find((u) => u.name === uni)?.id;
        if (unitId) unit_ids.push(unitId);
      });

      if (lessonsSelected) {
        const [anoS, trimestreS, licaoS] = lessonsSelected?.split(";");
        const year = Number(anoS.replace(/[^0-9]/g, ""));
        const quarter = Number(trimestreS.replace(/[^0-9]/g, ""));
        const number = Number(licaoS.replace(/[^0-9]/g, ""));

        const response = await BiService.getPresentMissingRelation({
          number,
          quarter,
          year,
          unit_ids,
        });

        if (!response?.items?.length) {
          toast.warning("Não há dados para serem exibidos", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }

        let unidades: any = [];
        let presentes: any = [];
        let faltantes: any = [];
        let totais: any = [];

        response?.items?.forEach((r) => {
          unidades.push(r.label);
          presentes.push(r.result.find((i) => i.key === "Presentes")?.value);
          faltantes.push(r.result.find((i) => i.key === "Faltantes")?.value);
          totais.push(r.result.find((i) => i.key === "Total")?.value);
        });

        setUnidades(unidades);
        setPresentes(presentes);
        setFaltantes(faltantes);
        setTotal(totais);
      } else {
        setUnidades([]);
        setPresentes([]);
        setFaltantes([]);
        setTotal([]);
      }
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Card
      style={{
        gap: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{ display: "flex", gap: 8, alignItems: "end", width: "100%" }}
      >
        <MultiSelect
          mt="md"
          withinPortal
          data={unitites.map((u) => u?.name) as any}
          placeholder="Selecione uma ou mais unidade(s)"
          label="Unidade(s)"
          classNames={classes}
          style={{ flex: 1 }}
          onChange={setUnitiesSelected}
        />
        <Select
          mt="md"
          withinPortal
          data={lessons.map(
            (l) =>
              `Ano: ${l.year}; Trimestre: ${l.quarter}; Lição: ${l.number}`,
          )}
          placeholder="Selecione uma lição"
          label="Lição"
          classNames={classes}
          style={{ flex: 1 }}
          onChange={setLessonsSelected}
        />
        <Button onClick={() => fetchData()} disabled={isLoading}>
          {isLoading ? (
            <Spinner color="light" />
          ) : (
            <label htmlFor="Buscar" style={{ cursor: "pointer" }}>
              Buscar
            </label>
          )}
        </Button>
      </div>
      <Chart option={option} />
    </Card>
  );
}

export const Button = styled.button`
  padding: 0rem 1.7rem;
  margin-bottom: 0.2rem;
  border-radius: 0.4rem;

  background-color: #3439a5;
  border: 0.1rem solid #2b2fa3;

  height: 3.5rem;
  min-width: 8.8rem;

  label {
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
  }
`;
