import { Select, SelectProps, createStyles, rem } from "@mantine/core";
const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },

  input: {
    height: rem(89.6), // 5.6rem
    paddingTop: rem(16),
    paddingLeft: rem(16),
    borderRadius: rem(16),
    fontSize: rem(18),
    backgroundColor: "#CACAE4",
    borderStyle: "solid",
    borderWidth: rem(1),
    borderColor: "#A8AABC",
    color: "#FFF"
  },
  label: {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: rem(18),
    paddingLeft: rem(16),
    paddingTop: rem(16),
    zIndex: 1,
    color: "#A8AABC"
  },
}));

type Props = SelectProps & {}
export function SelectInputForm({ ...rest }: Props){
  const { classes } = useStyles();

  return(
    <Select
      classNames={classes}
      {...rest}
    />
  );
}