import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { z } from "zod";
import { TextInfo } from '../../components';
import { ButtonComponent } from '../../components/Button';
import { InputComponent } from '../../components/Input';
import { Student, useUnitLesson } from '../../hooks/unitLesson';
import { ContainerButton, Content, MissingStudentsBox } from '../../styles/components';
import { Header } from './styles';

const schema = z.object({
  name: z.string()
    .nonempty("Nome é de preenchimento obrigatório")
    .toLowerCase(),
  phone: z.string()
  .refine((phone => {
    return phone.replace(/\D+/g, "").length > 0 ? phone.replace(/\D+/g, "").length === 11 : true;
  }), "Telefone é inválido."),
  baptism_date: z.string()
  .refine((date => {
    const rgex: RegExp = /([0-2][0-9]|3[0-1])\/(0[0-9]|1[0-2])\/[0-9]{4}/;
    return date ? rgex.test(date) : true;
  }), "Data de batismo é inválida."),
});

type UserFormData = z.infer<typeof schema>;

export function Register(){
  const { setUnitLesson, unitLesson } = useUnitLesson();
  const navigate = useNavigate();

  const {
    control, 
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: zodResolver(schema)
  });

  const nameWatch = useWatch({ control, name: 'name' });
  const phoneWatch = useWatch({ control, name: 'phone' });
  const baptismDateWatch = useWatch({ control, name: 'baptism_date' });
  
  const handleSubmitForm = (data: UserFormData) => {
    const compare = (a: Student, b: Student): number =>  {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }

    const newList = {
      ...unitLesson,
      students: [...unitLesson.students, {
        id: "",
        name: data?.name,
        present: false
      }].sort(compare)
    }

    setUnitLesson(newList);
    navigate("/attendanceRecord");
  }

  useEffect(() => {
    nameWatch && setValue("name", nameWatch.replace(/\u00C0-\u00FF/g, ''));
  }, [nameWatch])
  
  useEffect(() => {
    phoneWatch && setValue("phone", phoneWatch.replace(/\D/g, '').replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4'));
  }, [phoneWatch])

  useEffect(() => {
    baptismDateWatch && setValue("baptism_date", baptismDateWatch.replace(/\D+/g, '').replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3'));
  }, [baptismDateWatch])

  return (
    <>
      <Header>
        <div style={{ cursor: "pointer", display: "flex" }} onClick={() => navigate(-1)}>
          <FiChevronLeft/>
        </div>
        <label htmlFor="">Cadastro</label>
      </Header>

      <Content>
        <MissingStudentsBox>
          <strong>Atenção professor!</strong>
          <p>Este aluno só estará disponível na lista de membros a partir desta lição.</p>
        </MissingStudentsBox>

        <div>
          <InputComponent register={register("name")} maxLength={200} title='Nome completo'/>
          {errors.name && <TextInfo message={errors.name.message}/>}
        </div>
        <div>
          <InputComponent register={register("phone")} maxLength={16} title='Telefone' type="tel"/>
          {errors.phone && <TextInfo message={errors?.phone?.message}/>}
        </div>
        <div>
          <InputComponent register={register("baptism_date")} maxLength={10} title='Data de batismo' type="tel"/>
          {errors.baptism_date && <TextInfo message={errors.baptism_date.message}/>}
        </div>
      </Content>
      <ContainerButton>
        <ButtonComponent type='button' title='Cadastrar' onClick={handleSubmit(handleSubmitForm)}/>
      </ContainerButton>
    </>
  );
}