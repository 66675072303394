import styled from "styled-components";

export const Header = styled.div`
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.8rem 1.6rem;
  background-color: #3439a5;

  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 0.4rem;

  padding: 2.4rem 1.6rem;
  padding-bottom: 4rem;
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 0.8rem;
`;

export const Legend = styled.div<{ primary?: boolean }>`
  display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ primary }) => (primary ? "#017AEA" : "#CACAE4")};
  border: 0.1rem solid ${({ primary }) => (primary ? "#017AEA" : "#A8AABC")};
  color: #fff;
  font-size: 1.6rem;
  gap: 0.4rem;
  padding: 0.4rem 1.6rem;
  border-radius: 0.4rem;
`;

type PropsItemMarker = { marked?: boolean };
export const ItemMarker = styled.div<PropsItemMarker>`
  background-color: ${({ marked }) => (marked ? "#017AEA" : "#CACAE4")};
  border: 0.1rem solid ${({ marked }) => (marked ? "#017AEA" : "#A8AABC")};

  color: #fff;
  font-size: 1.6rem;

  padding: 1.6rem;
  border-radius: 0.8rem;
`;

export const Empty = styled.div`
  background-color: #9598df;
  border: 0.1rem solid #2b2fa3;

  color: #fff;
  font-size: 1.6rem;

  padding: 1.6rem;
  border-radius: 0.8rem;
`;

export const Button = styled.button`
  padding: 1.6rem 0rem;
  border-radius: 0.8rem;

  background-color: #3439a5;
  border: 0.1rem solid #2b2fa3;

  label {
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
  }
`;
