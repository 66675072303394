import { AxiosHttpClient } from "../httpClient/axios-http-client.service";

export type Totals = {
  total: {
    value: number
    data_type: string
  }
  items: [
    {
      label: string
      result: [
        {
          key: string
          value: number
          data_type: string
        },
        {
          key: string
          value: number
          data_type: string
        },
        {
          key: string
          value: number
          data_type: string
        }
      ]
    }
  ]
}

export type PresentPros = {
  year: number
  quarter: number
  number: number
  unit_ids: string[]
}

export type QuestionPros = {
  question_key: string
  unit_ids?: string[]
  year: number
  quarter: number
}

export class BiService extends AxiosHttpClient {
  public static async getPresentMissingRelation(params: PresentPros): Promise<Totals> {
    const { data: { data } } = await this.axiosHttpClient.post(`/bi/present-missing-relation`, params);
    return data;
  }

  public static async getQuestionAnswerIndex(params: QuestionPros): Promise<Totals> {
    const { data: { data } } = await this.axiosHttpClient.post(`/bi/question-answer-index`, params);
    return data;
  }
}