import { Select, createStyles, rem } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { BiService } from "../../services/bi/bi.service";
import { Lesson, LessonService } from "../../services/lesson/lesson.service";
import { Card, Chart } from "./styles";

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: rem(2.4)
  },

  input: {
    height: rem(56),
    paddingTop: rem(18),
  },

  label: {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

const questions = [
  {
    key: "studied-bible-and-lesson-daily",
    label: "Quantos estudaram diariamente a bíblia e a lição?",
  },
  {
    key: "participated-in-small-group-this-week",
    label: "Quantos participaram de um pequeno grupo nesta semana?",
  },
  {
    key: "gave-bible-study-this-week",
    label: "Quantos deram algum estudo bíblico nesta semana?",
  },
  {
    key: "carried-out-another-missionary-activity",
    label: "Quantos realizaram alguma outra atividade missionária utilizando seu talento?"
  }
]

type TSeries = {
  name: string
  type: string
  stack: string
  data: number[]
  lessons: string[]
}

export function RelacaoPerguntas(){
  const { classes } = useStyles();
  const [questionSelected, setQuestionSelected] = useState<string | null>(null);
  const [lessonSelected, setLessonSelected] = useState<string | null>(null);
  const [lessons, setLessons] = useState<Lesson[]>(new Array<Lesson>());
  const [series, setSeries] = useState<TSeries[]>(new Array<TSeries>());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<string[]>(new Array<string>());

  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data
    },
    yAxis: {
      type: 'category',
      boundaryGap: false,
      data: Array.from({ length: Math.max(...series.map(s => Math.max(...s.data))) + 4 } ).map((_, i) => i)
    },
    series
  };

  const fetch = async(): Promise<void> => {
    setIsLoading(true)
    try {
      const responseLessons = await LessonService.getAtDate();
      setLessons(responseLessons);

      let years = responseLessons.map(l => l.year)
      years = [...new Set(years)]
      
      const trimestresPorAno = years?.map(y => {
        return {
          year: y,
          quarters: [...new Set(responseLessons?.filter(l => l.year === y)?.map(r => r.quarter))]
        }
      });

      let newLessonsList: any[] = []
      trimestresPorAno.forEach(item => {
        newLessonsList=newLessonsList.concat(item.quarters.map(q => ({ year: item.year, quarter: q})))
      })

      setLessons(newLessonsList);
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    }finally{
      setIsLoading(false)
    }
  }

  const fetchData = async (): Promise<void> => {
    setIsLoading(true)
    try {
      if(lessonSelected){
        const [anoS, trimestreS] = lessonSelected?.split(";");
        const year = Number(anoS.replace(/[^0-9]/g,''));
        const quarter = Number(trimestreS.replace(/[^0-9]/g,''));

        let question_key: string = "";

        if(questionSelected)
          question_key = questions.find( q => q.label === questionSelected)?.key as any;
          
        const response = await BiService.getQuestionAnswerIndex({ question_key, year, quarter });
        console.log("response: ", response);
        
        let seriesData: any[] = []
        if(response?.items?.length){
          let listLessons: string[] = [];
          response.items.forEach(i => {
            const listLesson = i?.result?.map(r => r.key)
            listLessons = [
            ...listLessons,
            ...listLesson
            ]

            seriesData = [
              ...seriesData,
              {
                name: i?.label,
                type: 'line',
                stack: 'Total',
                data: i?.result?.map(r => r.value),
                lessons: i?.result?.map(r => r.key)
              }
            ]
          });        
          const l = [...new Set(listLessons)]
            .map(i => i.replace(/[^0-9]/g,''))
            .sort((a, b) => Number(a) - Number(b))
            .map(i => `Lição ${i}`);
          setData(l)
        }else {
          toast.warning("Não há dados para serem exibidos", { position: toast.POSITION.TOP_RIGHT });
          seriesData = [
            {
              // name: "",
              // type: 'line',
              // stack: 'Total',
              // data: [],
            }
          ]
        }
        setSeries(seriesData)
      }
    } catch (error: any) {
      toast.warning(error[0], { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])
    
  return (
    <Card style={{ gap: 16, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{display: 'flex', gap: 8, alignItems: "end", width: "100%"}}>
        <Select
          mt="md"
          withinPortal
          data={questions.map(l => l.label)}
          placeholder="Selecione uma pergunta"
          label="Pergunta"
          classNames={classes}
          style={{flex: 1}}
          onChange={setQuestionSelected}
          disabled={isLoading}
        />
        <Select
          mt="md"
          withinPortal
          data={lessons.map(l => `Ano: ${l.year}; Trimestre: ${l.quarter}`)}
          placeholder="Selecione um trimestre"
          label="Trimestre"
          classNames={classes}
          style={{flex: 1}}
          onChange={setLessonSelected}
          disabled={isLoading}
        />
        <Button 
          onClick={() => fetchData()} 
          disabled={isLoading}
        >
          { isLoading 
            ? <Spinner color='light' /> 
            : <label htmlFor="Buscar" style={{ cursor: "pointer" }}>Buscar</label>
          }
        </Button>
      </div>
      <h3>Relação de perguntas por unidade</h3>
      <Chart option={option} />
    </Card>
  )
}

export const Button = styled.button`
  padding: 0rem 1.7rem;
  margin-bottom: .2rem;
  border-radius: .4rem;

  background-color: #3439A5;
  border: .1rem solid #2B2FA3;

  height: 3.5rem;
  min-width: 8.8rem;

  label {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: bold;
  }
`;