import { TextInput, TextInputProps, createStyles, rem } from "@mantine/core";
import { RegisterOptions } from "react-hook-form";

type Props = TextInputProps & RegisterOptions & { }

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },

  input: {
    height: rem(89.6), // 5.6rem
    paddingTop: rem(16),
    paddingLeft: rem(16),
    borderRadius: rem(16),
    fontSize: rem(18),
    backgroundColor: "#e3e3ed",
    borderStyle: "solid",
    borderWidth: rem(1),
    borderColor: "#A8AABC",
    color: "#7f7f84",
  },
  label: {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: rem(20),
    paddingLeft: rem(16),
    paddingTop: rem(16),
    zIndex: 1,
    color: "#38383a"
  },
}));
export function InputForm({ register, ...rest }: any){
  const { classes } = useStyles();

  return(
    <TextInput classNames={classes} {...rest} {...register} autoComplete="off"/>
  )
}