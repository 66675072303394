import ReactEcharts from "echarts-for-react";
import styled from "styled-components";

export const Container = styled.div`
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;

  gap: 0.8rem;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  position: relative;
  width: 96vw !important;
  height: 96vh !important;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1.6rem 0.8rem;
  border-radius: 0.8rem;
`;

export const Chart = styled(ReactEcharts)`
  display: flex;
  width: 93vw !important;
  height: 80vh !important;
  top: 0;
`;
